import {
  Grid, Text,
} from "@mantine/core";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';
import { addCategoryThunk } from "../../Redux/Thunks/categoryThunks";
import { categorySelector } from "../../Redux/Selectors/categorySelector";
import { setIsLoggedIn } from "../../Redux/userSlice";
import MultiSelectDropDown from "./MultiSelectDropDown";
import { packsSelector } from "../../Redux/Selectors/packsSelector";
import Toggle from 'react-toggle'
import 'react-toggle/style.css';
import { addPackThunk } from "../../Redux/Thunks/specialPacks";
const imagefolder = "categories"
interface DataValue {
  pack_name: string;
  _id: string,
  isFree: boolean;
}
const AddEditMain = () => {
  const location = useLocation();
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const multiselectRef = useRef<any>(null);
  const { packs, totalPacks }: any = useSelector(packsSelector)
  const [addMode, setAddMode] = useState(true)
  const [toggle, setToggle] = useState(false)

  const [options, setOptions] = useState<any[]>([]);
  const [assignedPack, setAssignedPack] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [dataVal, setDataVal] = useState<DataValue>({
    pack_name: "",
    _id: '',
    isFree: toggle
  })

  function logoutUser() {
    dispatch(setIsLoggedIn());
    localStorage.removeItem("currentToken");
    localStorage.removeItem("userId");
    navigate("/login")
  }

  function checkLoginToken() {
    let token = localStorage.getItem("currentToken")
    if (!token) {
      logoutUser()
    }
    else {
      return token
    }
  }

  useEffect(() => {
    if (location && location.state && Object.entries(location.state).length > 0) {
      setDataVal({
        pack_name: location.state.name || "",
        _id: location.state._id,
        isFree: location.state.isFree
      })
      setAddMode(false)
    }
  }, [])

  const validationSchema = Yup.object().shape({
    pack_name: Yup.string().required("Name is required").trim(),

  });


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      pack_name: dataVal.pack_name,
      _id: dataVal._id,
      isFree: dataVal.isFree
    },
    validationSchema,
    onSubmit: async (data, { resetForm }) => {
      let token = checkLoginToken()
      let valuse = {
        ...data,
        token: token
      }
      try {
        const response: any = await dispatch(addPackThunk(valuse))
        console.log("shdftgeyufgnchjugfyibreyf", response.payload);
        if (response.payload.response && response.payload.response.status) {
          if (response.payload.response.status == 401) {
            logoutUser()
          }
          toast.error(response.payload.response.data.message)
        } else {
          toast.success(response.payload.data.message)
          resetForm()
          navigate("/packs")
        }
      } catch (error) {
        console.log("error", error);

      }

      //  if(res.payload.response){

      //  }

      // if (addMode) {

      //   setDataVal({
      //     pack_name: "",
      //     isFree: toggle
      //   })

      // }
    }
  });

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);


  }


  useEffect(() => {

console.log("hghjasdsvdyuasdgasd",location);


    if (location && location.state && Object.entries(location.state).length > 0) {
      setDataVal({
        pack_name: location.state.pack_name || "",
        _id: location.state._id ,
        isFree: location.state.isFree || false
      })
      setAddMode(false)
    }
  }, [])










  // const getUsersList = () => {

  //   let tempOptions: any = [];

  //   packs.map((item: any) => {
  //     tempOptions.push({ name: item.pack_name, id: item._id });
  //   });


  //   setOptions([...tempOptions]);

  // };
  // useEffect(() => {
  //   getUsersList();
  // }, []);
  // const getSelectedPack = (packSelected: any) => {
  //   console.log(packSelected, "packSelected");
  //   setAssignedPack([...packSelected]);
  // };
  const handleToggle = (value: any) => {
    // setToggle(false)
    console.log("jgfyusdgfhjfdjsfydf", value);

  }
  return (
    <div className="main_layout">

      <h2>
        {addMode ? "Add" : "Edit"} Special Pack
      </h2>
      <div className="add_edit_box">
        <form className='add-category' onSubmit={formik.handleSubmit}>
          <div className="flex-fg">
            <div className='form-group'>
              <label>Pack Name</label>
              <input
                name="pack_name"
                type="text"
                placeholder="Enter Name"
                className={
                  'form-control' +
                  (formik.errors.pack_name && formik.touched.pack_name
                    ? ' is-invalid'
                    : '')
                }
                onChange={formik.handleChange}
                value={formik.values.pack_name}
              />
              <div className="invalid-feedback">
                {formik.errors.pack_name && formik.touched.pack_name
                  ? formik.errors.pack_name
                  : null}
              </div>
            </div>
            <div className='form-group user_select'  >
              <label>Is Free</label>
              <Toggle
                id='isFree'
                name='isFree'
                checked={formik.values.isFree}
                onChange={formik.handleChange}
                icons={false}
              />
            </div>


          </div>
          <button type="submit" className='btn main_btn1 text-white mt-4'>Submit</button>
        </form>


      </div >
    </div >
  );
};

export default AddEditMain;

