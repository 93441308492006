import React from 'react'
import Sidebar from "../Sidebar/Sidebar";
import Main from "./Main";
import { userSelector } from '../../Redux/Selectors/userSelector';
import { useSelector } from 'react-redux';
export default function Dashboard() {
  const {users}=useSelector(userSelector)

  return (
    <div className="general-layout">
      <Sidebar />
      <Main />
    </div>
  )
}
