import {
    Grid, Text,
    Table,
} from "@mantine/core";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { addImageThunk } from "../../Redux/Thunks/imageThunk";
import { toast } from 'react-toastify';
import { imageSelector } from "../../Redux/Selectors/imageSelector";
import StringArrayInput from "../Custom/StringArrayInput";
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';
import { setIsLoggedIn } from "../../Redux/userSlice";
import { updateValidationError } from "../../Redux/imageSlice";
import { ReactComponent as DownArrowSvg } from "../../images/down-arrow-6402.svg"
import MultiSelectDropDown from "../Packs/MultiSelectDropDown";
import { packsSelector } from "../../Redux/Selectors/packsSelector";
import { publisherSelector } from "../../Redux/Selectors/publisherSelector";
import { Loader } from "rsuite";
const imagefolder = "images"
interface AddEditMainProps {
    mode: boolean,
    setMode: () => boolean
}

const AddEditMain = () => {
    // console.log("sadfffffff",data);

    const location = useLocation();
    const navigate = useNavigate()
    const { activeCategories, image } = useSelector(imageSelector)
    const [enteredHashTags, setEnteredHashTags] = useState([""]);
    const [options, setOptions] = useState<any>([]);
    const [assignedPack, setAssignedPack] = useState<any>([]);
    const [subCategories, setSubCategories] = useState([])
    const { packs, allPack } = useSelector(packsSelector);
    const dispatch = useDispatch()
    const multiselectRef = useRef<any>(null);
    const [addMode, setAddMode] = useState(false)
    const { publishers } = useSelector(publisherSelector);
    const [loading, setLoading] = useState(false);
    const [hdLoading, setHdLoading] = useState(false);

    const [dataVal, setDataVal] = useState({
        // name: "",
        type: "",
        image_hd: "",
        image_low: "",
        category_id: "",
        subcategory_id: "",
        publisher: "",
        title: "",
        description: "",
        url: "",
        image_virent_id: "",
        price: "",
        _id: ''
    })

    function logoutUser() {
        dispatch(setIsLoggedIn());
        localStorage.removeItem("currentToken");
        localStorage.removeItem("userId");
        navigate("/login")
    }

    function checkLoginToken() {
        let token = localStorage.getItem("currentToken")
        if (!token) {
            logoutUser()
        }
        else {
            return token
        }
    }


    useEffect(() => {
        if (image && Object.entries(image).length > 0) {
            setDataVal({
                // name: image.name || "",
                type: image.type.length > 0 ? image.type[0] : "",
                image_hd: image.image_hd || "",
                image_low: image.image_low || "",
                category_id: image.category_id ? image.category_id._id : "",
                subcategory_id: image.subcategory_id ? image.subcategory_id._id : "",
                publisher: image?.publisher,
                title: image.title,
                description: image.description,
                url: image.url,
                image_virent_id: image.image_virent_id,
                price: image.price,
                _id: image._id || ''
            })
            if (image && image.category_id) {
                let obj = {
                    target: {
                        value: image.category_id._id
                    }
                }
                categoryChange(obj)

            }

            setEnteredHashTags(image.hashtags)

            setAddMode(true)
        }

    }, [])

    const validationSchema = Yup.object().shape({
        // name: Yup.string().required("Name is required"),
        type: Yup.string().required("Type is required"),
        //publisher: Yup.string().required("Publisher is required"),
        category_id: Yup.string().required("Category is required"),
        // subcategory_id: Yup.string().notRequired(),
        title: Yup.string().required("Title is required"),

        ...(addMode ? { image_hd: Yup.string().required("HD Resolution Image is required") } : {}),
        ...(addMode ? { image_low: Yup.string().required("Low Resolution Image is required") } : {}),
    });


    const { validationError } = useSelector(imageSelector)

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            // name: dataVal.name,
            type: dataVal.type,
            image_low: dataVal.image_low,
            image_hd: dataVal.image_hd,
            category_id: dataVal.category_id,
            subcategory_id: dataVal.subcategory_id,
            publisher: dataVal.publisher,
            title: dataVal.title,
            description: dataVal.description,
            url: dataVal.url,
            image_virent_id: dataVal.image_virent_id,
            price: dataVal.price,

        },
        validationSchema,

        onSubmit: async (data, { resetForm }) => {
            let token = localStorage.getItem("currentToken")


            const res = await handleCreateImage(data)

            const assignedPackTopack = assignedPack.map(async (item: any) => {
                return await axios.put(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/specialpack/update/${item}`, { item: image._id }, { 'headers': { 'Authorization': `${token}` } })
            })


            if (addMode) {
                setFileHdRes(null)
                setFileLowRes(null)
                setFileDataURLHdRes(null)
                setFileDataURLLowRes(null)
                // setDataVal({
                //     // name: "",
                //     type: "",
                //     image_hd: "",
                //     image_low: "",
                //     category_id: "",
                //     subcategory_id: "",
                //     publisher: "",
                //     title: "",
                //     description: "",
                //     url: "",
                //     image_virent_id: "",
                //     price: "",
                //     _id: ""
                // })
                resetForm()
                navigate("/images")

            }

        }
    });


    const [fileHdRes, setFileHdRes] = useState<any>(null);
    const [fileDataURLHdRes, setFileDataURLHdRes] = useState(null);

    const changeHandlerHdRes = (e: any) => {
        const file = e.target.files[0];

        setFileHdRes(file);
    }

    useEffect(() => {
        let fileReader: any, isCancel = false;
        if (fileHdRes) {
            fileReader = new FileReader();
            fileReader.onload = (e: any) => {
                const { result } = e.target;
                if (result && !isCancel) {
                    setFileDataURLHdRes(result)
                }
            }
            fileReader.readAsDataURL(fileHdRes);
        }
        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        }

    }, [fileHdRes]);


    const [fileLowRes, setFileLowRes] = useState<any>(null);
    const [fileDataURLLowRes, setFileDataURLLowRes] = useState(null);
    const [fileImage, setFileImage] = useState(null);

    const changeHandlerLowRes = (e: any) => {
        const file = e.target.files[0];

        setFileLowRes(file);
    }

    const changeHandlerImage = (e: any) => {
        const file = e.target.files[0];
        setFileImage(file);
    }

    useEffect(() => {
        let fileReader: any, isCancel = false;
        if (fileLowRes) {
            fileReader = new FileReader();
            fileReader.onload = (e: any) => {
                const { result } = e.target;
                if (result && !isCancel) {
                    setFileDataURLLowRes(result)
                }
            }
            fileReader.readAsDataURL(fileLowRes);
        }
        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        }

    }, [fileLowRes]);


    const handleCreateImage = async (data: any) => {

        try {
            // console.log("dasssssssssssssssssssssssssssssss", assignedPack, data);

            let token = checkLoginToken()
            let payload: any = { ...data }
            payload.token = token


            // if (fileHdRes) {
            //     let formData = new FormData()

            //     formData.append("folder", imagefolder);

            //     formData.append("image", fileHdRes);

            //     let resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/uploadHdImage`, formData, { headers: { "Authorization": `${token}` } })
            //     if (resp.statusText == "OK") {
            //         let filename = resp.data.body.filename;
            //         payload.image_hd = filename


            //     }
            // }
            // else {
            //     payload.image_hd = data.image_hd
            // }

            // if (fileLowRes) {
            //     let formData = new FormData()

            //     formData.append("folder", imagefolder);

            //     formData.append("image", fileLowRes);

            //     let resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/uploadLowImage`, formData, { headers: { "Authorization": `${token}` } })
            //     if (resp.statusText == "OK") {
            //         let filename = resp.data.body.filename;
            //         payload.image_low = filename


            //     }
            // }
            // else {
            //     payload.image_low = data.image_low
            // }

            if (fileImage) {
                let formData = new FormData()

                formData.append("folder", imagefolder);

                formData.append("image", fileImage);

                let resp = await axios.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/uploadImage`, formData, { headers: { "Authorization": `${token}` } })
                if (resp.statusText == "OK") {
                    payload.image_hd = resp.data.body.hdFilename;
                    payload.image_low = resp.data.body.lowResFilename;
                }
            }
            else {
                payload.image_hd = data.image_hd
                payload.image_low = data.image_low
            }

            payload.hashtags = enteredHashTags

            if (dataVal._id) payload._id = dataVal._id;
            if (assignedPack.length > 0) {
                payload.added_in_pack = true
            } else {
                payload.added_in_pack = false
            }
            let response: any = await dispatch(addImageThunk(payload))
            console.log('response.payload', payload)


            if (response.payload.response && response.payload.response.status) {
                if (response.payload.response.status == 401) {
                    logoutUser()
                }
                toast.error(response.payload.response.data.message)
            }
            else {
                if (formik.errors && formik.touched) {
                    toast.success(response.payload.data.message)
                    navigate("/images")

                }
            }

        } catch (err) {
            console.log(err);
        }
    };



    const categoryChange = (e: any) => {

        let categoryDetail = e.target.value && e.target.value != '' ? activeCategories.find((ele: any) => ele._id == e.target.value) : null

        if (categoryDetail) {
            console.log(categoryDetail.subcategories.length)
            setSubCategories(categoryDetail.subcategories)
        }
    }

    const imageTypes = [
        {
            value: "Phone",
            label: "Phone"
        },
        {
            value: "Tablet",
            label: "Tablet"
        },
        {
            value: "Desktop",
            label: "Desktop"
        },
    ]



    const getPacksList = () => {

        let tempOptions: any = [];

        allPack.map((item: any) => {
            tempOptions.push({ name: item.pack_name, id: item._id });
        });


        setOptions([...tempOptions]);

    };
    useEffect(() => {
        getPacksList();
    }, []);
    const getSelectedPack = (packSelected: any) => {
        setAssignedPack([...packSelected]);
    };


    return (

        <div className="add_edit_box add_edit_box_image" style={{ marginBottom: "35px" }}>
            <h2>
                Edit Image Details

            </h2>
            <form className='add-category' onSubmit={formik.handleSubmit}
            >
                <div className="flex-fg">
                    {/* <div className='form-group cus-img'>
                        <label>HD Image</label>
                        <input
                            name="image_hd"
                            type="file"
                            id={addMode ? formik.values.image_hd : ""}
                            className={
                                'form-control'
                            }
                            accept='.png, .jpg, .jpeg'
                            onChange={(e: any) => {
                                formik.handleChange(e)
                                changeHandlerHdRes(e)
                            }}
                        />
                        <div className="invalid-feedback">
                            {formik.errors.image_hd && formik.touched.image_hd
                                ? formik.errors.image_hd
                                : null}
                        </div>
                        {
                            hdLoading ? <Loader content="Image Uploading..." /> : null
                        }
                    </div>

                    <div className='form-group'>
                        <label>Low Image</label>
                        <input
                            name="image_low"
                            type="file"
                            id={addMode ? formik.values.image_low : ""}
                            className={
                                'form-control'
                            }
                            accept='.png, .jpg, .jpeg'
                            onChange={(e: any) => {
                                formik.handleChange(e)
                                changeHandlerLowRes(e)
                            }}
                        />
                        <div className="invalid-feedback">
                            {formik.errors.image_low && formik.touched.image_low
                                ? formik.errors.image_low
                                : null}
                        </div>
                        {loading ? <Loader content="Image Uploading..." /> : ""}
                    </div> */}

                    <div className='form-group'>
                        <label>Image</label>
                        <input
                            name="image"
                            type="file"
                            className={
                                'form-control'
                            }
                            accept='.png, .jpg, .jpeg'
                            onChange={(e: any) => {
                                changeHandlerImage(e)
                            }}
                        />
                        {loading ? <Loader content="Image Uploading..." /> : ""}
                    </div>

                    <div className='form-group'>
                        <label>Select Category</label>
                        <div className="select_icon">
                            <DownArrowSvg />
                            <select
                                name="category_id"
                                className={
                                    'form-control' +
                                    (formik.errors.category_id && formik.touched.category_id
                                        ? ' is-invalid'
                                        : '')
                                }
                                onChange={(e) => {
                                    formik.handleChange(e)
                                    categoryChange(e)
                                }}
                                value={formik.values.category_id}
                            >
                                <option value="">
                                    Select a category{" "}
                                </option>

                                {
                                    activeCategories && activeCategories.map((ele: any) => {
                                        return (
                                            <option value={ele._id}>
                                                {ele.name}
                                            </option>
                                        )
                                    })
                                }

                            </select>
                        </div>
                        <div className="invalid-feedback">
                            {formik.errors.category_id && formik.touched.category_id
                                ? formik.errors.category_id
                                : null}
                        </div>
                    </div>

                    <div className='form-group'>
                        <label>Select Sub Category</label>
                        <div className="select_icon">
                            <DownArrowSvg />
                            <select
                                name="subcategory_id"
                                className={
                                    'form-control' +
                                    (formik.errors.subcategory_id && formik.touched.subcategory_id
                                        ? ' is-invalid'
                                        : '')
                                }
                                onChange={(e) => {
                                    formik.handleChange(e)
                                }}
                                value={formik.values.subcategory_id}
                            >
                                <option value="">
                                    {subCategories.length > 0 ? "Select Sub category" : "Please select category first"} {" "}
                                </option>

                                {
                                    subCategories.length > 0 && subCategories.map((ele: any) => {
                                        return (
                                            <option value={ele._id}>
                                                {ele.name}
                                            </option>
                                        )
                                    })


                                }

                            </select>
                        </div>
                        <div className="invalid-feedback">
                            {formik.errors.subcategory_id && formik.touched.subcategory_id
                                ? formik.errors.subcategory_id
                                : null}
                        </div>
                    </div>


                    <div className='form-group'>
                        <label>Select Types</label>
                        <div className="select_icon">
                            <DownArrowSvg />
                            <select
                                name="type"
                                className={
                                    'form-control' +
                                    (formik.errors.type && formik.touched.type
                                        ? ' is-invalid'
                                        : '')
                                }
                                onChange={(e) => {
                                    formik.handleChange(e)
                                    categoryChange(e)
                                }}
                                value={formik.values.type}

                            >
                                <option value="" disabled>
                                    Select Type
                                </option>

                                {
                                    imageTypes.length > 0 && imageTypes.map((ele: any) => {
                                        return (
                                            <option value={ele.value}>
                                                {ele.label}
                                            </option>
                                        )
                                    })


                                }

                            </select>
                        </div>
                        <div className="invalid-feedback">
                            {formik.errors.type && formik.touched.type
                                ? formik.errors.type
                                : null}
                        </div>
                    </div>

                    <div className='form-group tags'>
                        <label>Hashtags</label>

                        {/* <StringArrayInput values={enteredHashTags} onChange={setEnteredHashTags} /> */}
                    </div>
                    <div className="form-group cus-img">
                        <label>Publisher</label>
                        <div className="select_icon">
                            <DownArrowSvg />
                            <select
                                name="publisher"
                                className={"form-control"}
                                value={formik.values.publisher}
                                onChange={formik.handleChange}
                            >
                                <option value="">Select </option>

                                {publishers &&
                                    publishers?.map((ele: any) => {
                                        return <option value={ele.name}>{ele.name}</option>;
                                    })}
                            </select>
                        </div>
                    </div>
                    {/* <div className='form-group'>
                        <label>Publisher</label>
                        <input
                            name="publisher"
                            type="text"
                            placeholder="Enter Publisher"
                            className={
                                'form-control' +
                                (formik.errors.publisher && formik.touched.publisher
                                    ? ' is-invalid'
                                    : '')
                            }
                            onChange={formik.handleChange}
                            value={formik.values.publisher}
                        />
                        <div className="invalid-feedback">
                            {formik.errors.publisher && formik.touched.publisher
                                ? formik.errors.publisher
                                : null}
                        </div>
                    </div> */}

                    <div className='form-group'>
                        <label>Title</label>
                        <input
                            name="title"
                            type="text"
                            placeholder="Enter Title"
                            className={
                                'form-control' +
                                (formik.errors.title && formik.touched.title
                                    ? ' is-invalid'
                                    : '')
                            }
                            onChange={formik.handleChange}
                            value={formik.values.title}
                        />
                        <div className="invalid-feedback">
                            {formik.errors.title && formik.touched.title
                                ? formik.errors.title
                                : null}
                        </div>
                    </div>


                    <div className='form-group'>
                        <label>Description
                            <div
                                style={{
                                    color: "grey",
                                    fontSize: "15px",
                                    marginLeft: "6px",
                                }}
                            >
                                {" "}
                                (optional)
                            </div>
                        </label>
                        <input
                            name="description"
                            type="text"
                            placeholder="Enter Description"
                            className={
                                'form-control' +
                                (formik.errors.description && formik.touched.description
                                    ? ' is-invalid'
                                    : '')
                            }
                            onChange={formik.handleChange}
                            value={formik.values.description}
                        />
                        <div className="invalid-feedback">
                            {formik.errors.description && formik.touched.description
                                ? formik.errors.description
                                : null}
                        </div>
                    </div>


                    <div className='form-group'>
                        <label style={{ display: "flex" }}>
                            Url
                            <div style={{ color: "grey", fontSize: "15px", marginLeft: "6px" }}> (optional)</div>
                        </label>
                        <input
                            name="url"
                            type="text"
                            placeholder="Enter Url"
                            className={
                                'form-control' +
                                (formik.errors.url && formik.touched.url
                                    ? ' is-invalid'
                                    : '')
                            }
                            onChange={formik.handleChange}
                            value={formik.values.url}
                        />
                        <div className="invalid-feedback">
                            {formik.errors.url && formik.touched.url
                                ? formik.errors.url
                                : null}
                        </div>
                    </div>


                    <div className='form-group'>
                        <label style={{ display: "flex" }}>
                            Image variant id
                            <div style={{ color: "grey", fontSize: "15px", marginLeft: "6px" }}> (optional)</div>
                        </label>
                        <input
                            name="image_virent_id"
                            type="text"
                            placeholder="Enter Image Virent Id"
                            className={
                                'form-control' +
                                (formik.errors.image_virent_id && formik.touched.image_virent_id
                                    ? ' is-invalid'
                                    : '')
                            }
                            onChange={formik.handleChange}
                            value={formik.values.image_virent_id}
                        />
                        <div className="invalid-feedback">
                            {formik.errors.image_virent_id && formik.touched.image_virent_id
                                ? formik.errors.image_virent_id
                                : null}
                        </div>
                    </div>

                    <div className='form-group'>
                        <label style={{ display: "flex" }}>
                            Enter Price
                            <div style={{ color: "grey", fontSize: "15px", marginLeft: "6px" }}> (optional)</div>
                        </label>
                        <input
                            name="price"
                            type="number"
                            placeholder="Enter Price"
                            className={
                                'form-control' +
                                (formik.errors.price && formik.touched.price
                                    ? ' is-invalid'
                                    : '')
                            }
                            onChange={formik.handleChange}
                            value={formik.values.price}
                        />
                        <div className="invalid-feedback">
                            {formik.errors.price && formik.touched.price
                                ? formik.errors.price
                                : null}
                        </div>
                    </div>
                    <div className="form-group cus-img">
                        <label>Assing image to packs</label>
                        <MultiSelectDropDown
                            options={options}
                            onSelectUser={getSelectedPack}
                            multiselectRef={multiselectRef}

                        />
                    </div>

                    {
                        !addMode && (
                            <>
                                <div className='form-group'>
                                    <label>Number of Likes</label>
                                    <input
                                        name="likesCount"
                                        type="number"
                                        disabled
                                        placeholder="Number of Likes"
                                        className={
                                            'form-control'
                                        }
                                        value={image.likes.length}
                                    />
                                </div>

                                <div className='form-group'>
                                    <label>Number of Comments</label>
                                    <input
                                        name="commentsCount"
                                        type="number"
                                        disabled
                                        placeholder="Number of Comments"
                                        className={
                                            'form-control'
                                        }
                                        value={image.comments.length}
                                    />
                                </div>
                            </>
                        )
                    }


                </div>

                <button type="submit" className='autoclick btn main_btn1 text-white mt-4' style={{ width: "190px" }}>Submit</button>
            </form>
        </div>

    );
};

export default AddEditMain;
