import { configureStore } from "@reduxjs/toolkit";
import { persistCombineReducers } from "reduxjs-toolkit-persist";
import storage from "reduxjs-toolkit-persist/lib/storage";
import autoMergeLevel1 from "reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1";
import userReducer from "../Redux/userSlice";
import helperReducer from "../Redux/helperSlice";
import categoryReducer from "../Redux/categorySlice";
import subcategoryReducer from "../Redux/subcategorySlice";
import imageReducer from '../Redux/imageSlice'
import purchaseReducer from "./purchaseSlice";
import notificationReducer from "./notificationSlice";
import packsSlice from "./packsSlice";
import publisherSlice from "./publisherSlice";


const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel1,
};

const _persistedReducer = persistCombineReducers(persistConfig, {
  user: userReducer,
  helper: helperReducer,
  category: categoryReducer,
  subcategory: subcategoryReducer,
  image: imageReducer,
  transaction:purchaseReducer,
  notification:notificationReducer,
  packs:packsSlice,
  publisher:publisherSlice
});
export type RootState = ReturnType<typeof store.getState>;
export const store = configureStore({
  reducer: _persistedReducer,
});
