import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const api_url = process.env.REACT_APP_BACKEND_API_BASE_URL;

export const loginThunk = createAsyncThunk(
    "admin/login",
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/login`, {
                email: body.email,
                password: body.password,
            })
            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
)


export const getUsersThunk = createAsyncThunk(
    'user/all',
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.get(`${api_url}/users?page=${body.page}&limit=${body.limit}`, { 'headers': { 'Authorization': `${body.token}` } })

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
)


export const uploadImageThunk = createAsyncThunk(
    'upload/image',
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.get(`${api_url}/users?page=${body.page}&limit=${body.limit}`, { 'headers': { 'Authorization': `${body.token}` } })

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
)



