import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const api_url = process.env.REACT_APP_BACKEND_API_BASE_URL;

export const getPurchaseDetailTransactions = createAsyncThunk(
  'transaction/all',
  async (body: any, { rejectWithValue }) => {
    try {
      const data = await axios.get(`${api_url}/payment/transactions?page=${body.page}&limit=${body.limit}`, { 'headers': { 'Authorization': `${body.token}` } })
      console.log("here=========>",data);

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
