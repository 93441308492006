import { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import AddEditMain from "./AddEditMain";
// import Add from "./Add";
import { imageSelector } from "../../Redux/Selectors/imageSelector";
import { useSelector } from "react-redux";
import AddNew from "./AddNew";
 

// import AddForm from "./AddForm";

const AddEdit = () => {
  // let newComponent = <AddEditMain />
  const { validationError } = useSelector(imageSelector)
  // const [addEditMains, setAddEditMains] = useState<any[]>([newComponent])
  const [addMode, setAddMode] = useState(true)

   

  
  return (
    <>
      <div className="general-layout">
        <Sidebar />
        
        {/* <AddForm/> */}
        <div className="main_layout">
          
            <div className="sticky_header">
              {
                validationError? <AddNew  />: <AddEditMain   />
              }
           
            
            </div>
       

        </div>

\

      </div>
    </>
  );
};

export default AddEdit;
