import { createSlice /* PayloadAction */ } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getCategoriesThunk, addCategoryThunk, deleteCategoryThunk } from "./Thunks/categoryThunks";


export interface CategoryState {
    totalCategories: any,
    categories: any;
    category: Object;
    page: any;
    limit: any;
    loadingStatus: any;
    getCategoriesError: any;
    addCategoryError: any;
    deleteCategoryError: any
}

const initialState: CategoryState = {
    totalCategories: 1,
    categories: [],
    category: {},
    page: 1,
    limit: 10,
    loadingStatus: "loading",
    getCategoriesError: "",
    addCategoryError: "",
    deleteCategoryError: ""
};


export const categorySlice = createSlice({
    name: "category",
    initialState,
    reducers: {
        getAll: (state, action) => {
            state.categories = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder

            // Get categories
            .addCase(getCategoriesThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.getCategoriesError = "";
                // state.categories = [];
            })
            .addCase(getCategoriesThunk.fulfilled, (state, action) => {
                console.log("GET fulffilled")
                state.loadingStatus = "";
                state.getCategoriesError = "";
                state.categories = action.payload.data.body.data;
                state.totalCategories = action.payload.data.body.count

            })
            .addCase(getCategoriesThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
                state.getCategoriesError = action.payload.response.data.message;
            })


            // Add Category
            .addCase(addCategoryThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.addCategoryError = "";
                state.category = {};
            })
            .addCase(addCategoryThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.addCategoryError = "";
                state.category = {};
            })
            .addCase(addCategoryThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
                state.addCategoryError = action.payload.response.data.message;
                state.category = {};

                // toast.error(action.payload.response.data.message)

            })



            // Delete Category
            .addCase(deleteCategoryThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.deleteCategoryError = "";
            })
            .addCase(deleteCategoryThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.deleteCategoryError = "";
            })
            .addCase(deleteCategoryThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
                state.deleteCategoryError = action.payload.response.data.message;
            })

    }
});

// Action creators are generated for each case reducer function
export const { getAll } =
    categorySlice.actions;

export default categorySlice.reducer;
