import Sidebar from '../Sidebar/Sidebar'
import React from 'react'
import AddEdit from './AddEdit'

export default function EditUser() {
  return (
    <div>
      <div className="general-layout">
        <Sidebar />
       <AddEdit/>
      </div>
      
    </div>
  )
}
