import { createSlice /* PayloadAction */ } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getSubCategoriesThunk, getAllActiveCategoriesThunk, addSubCategoryThunk, deleteSubCategoryThunk } from "./Thunks/subcategoryThunk";

export interface SubCategoryState {
    subcategories: any;
    subcategory: Object;
    totalSubcategories: any;
    activeCategories: any;
    page: any;
    limit: any;
    loadingStatus: any;
    getSubCategoriesError: any;
    getAllActiveCategoriesError: any;
    addSubCategoryError: any;
    deleteSubCategoryError: any
}

const initialState: SubCategoryState = {
    totalSubcategories: 1,
    subcategories: [],
    subcategory: {},
    activeCategories: [],
    page: 1,
    limit: 10,
    loadingStatus: "loading",
    getSubCategoriesError: "",
    getAllActiveCategoriesError: "",
    addSubCategoryError: "",
    deleteSubCategoryError: ""
};


export const subcategorySlice = createSlice({
    name: "subcategory",
    initialState,
    reducers: {
        getAll: (state, action) => {
            state.subcategories = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder

            // Get Subcategories
            .addCase(getSubCategoriesThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.getSubCategoriesError = "";
                // state.subcategories = [];
            })
            .addCase(getSubCategoriesThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.getSubCategoriesError = "";
                state.subcategories = action.payload.data.body.data;
                state.totalSubcategories = action.payload.data.body.count
            })
            .addCase(getSubCategoriesThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
                state.getSubCategoriesError = action.payload.response.data.message;
            })


            // Get All Active Categories
            .addCase(getAllActiveCategoriesThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.getAllActiveCategoriesError = "";
            })
            .addCase(getAllActiveCategoriesThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.getAllActiveCategoriesError = "";
                state.activeCategories = action.payload.data.body.data;
            })
            .addCase(getAllActiveCategoriesThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
                state.getAllActiveCategoriesError = action.payload.response.data.message;
            })


            // Add SubCategory
            .addCase(addSubCategoryThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.addSubCategoryError = "";
                state.subcategory = {};
            })
            .addCase(addSubCategoryThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.addSubCategoryError = ""
                state.subcategory = {};
            })
            .addCase(addSubCategoryThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
                state.addSubCategoryError = action.payload.response.data.message;
                state.subcategory = {};

                // toast.error(action.payload.response.data.message)
            })


            // Delete SubCategory
            .addCase(deleteSubCategoryThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.deleteSubCategoryError = "";
            })
            .addCase(deleteSubCategoryThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.deleteSubCategoryError = "";
            })
            .addCase(deleteSubCategoryThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
                state.deleteSubCategoryError = action.payload.response.data.message;
            })

    }
});

// Action creators are generated for each case reducer function
export const { getAll } =
    subcategorySlice.actions;

export default subcategorySlice.reducer;
