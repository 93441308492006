import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

export default function ResetPassword() {
  const [user_id,setUser_id] = useState(new URLSearchParams(window.location.search).get('id'))
  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem('visitedResetPassword');
    if (hasVisitedBefore) {
      // navigate('*'); // Redirect to a "Page Not Found" component or another page
    } else {
      localStorage.setItem('visitedResetPassword', 'true');
    }
  }, []);
  const navigate = useNavigate()
  useEffect(() => {
    // const urlParams = new URLSearchParams(window.location.search).get('id');
    // const id:any = urlParams.get('id');
    // setUser_id(id)
    // console.log(id); // Access the id parameter
  }, []);
console.log(user_id,);

  const formik = useFormik({
    initialValues: {
      userId: user_id,
      newPassword: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().required('Password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    }),
    onSubmit: async (values) => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/resetPassword`, values)
        console.log(res);

        navigate('/login')
        

      } catch (error) {

        console.log(error);
      }


    }
  })

  return (
    <div>
      <div className="add_image-form1 category-frm">


        <form className='add-category' onSubmit={formik.handleSubmit}>
          <div className="h2_div">
            <h2 className="text-center">
              Reset Password
            </h2>
          </div>
          <div className="flex-fg">
            <div className='form-group'>
              <p><label  >New Password:</label></p>
              <input type="text" placeholder="Enter new password"  onChange={formik.handleChange} value={formik.values.newPassword} name="newPassword" />
              <div className="invalid">
                {formik.errors.newPassword && formik.touched.newPassword
                  ? formik.errors.newPassword
                  : null}
              </div>
            </div>
            <div className='form-group'>
              <p><label  >Confirm Password:</label></p>
              <input type="text" placeholder="Confirm password" onChange={formik.handleChange} value={formik.values.confirmPassword} name="confirmPassword" />
              <div className="invalid">
                {formik.errors.confirmPassword && formik.touched.confirmPassword
                  ? formik.errors.confirmPassword
                  : null}
              </div>
            </div>
          </div>

          <button type="submit" className='btn main_btn1 text-white mt-4 sbmt'>Reset Password</button>
        </form>

      </div>







 
    </div>
  )
}
