import { packsSelector } from '../../Redux/Selectors/packsSelector'
import { useDispatch, useSelector } from "react-redux";
import Sidebar from '../../components/Sidebar/Sidebar'
import React, { useEffect } from 'react'
import { setIsLoggedIn } from "../../Redux/userSlice";
import { useLocation, useNavigate } from "react-router";
import { getPacksImagesThunk } from '../../Redux/Thunks/specialPacks';
import { toast } from 'react-toastify';
import axios from 'axios';

export default function PacksImages() {
  const { imagesOfPacks }: any = useSelector(packsSelector)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();

  function logoutUser() {
    dispatch(setIsLoggedIn());
    localStorage.removeItem("currentToken");
    localStorage.removeItem("userId");
    navigate("/login")
  }
  console.log("kllllllll", location);

  function checkLoginToken() {
    let token = localStorage.getItem("currentToken")
    if (!token) {
      logoutUser()
    }
    else {
      return token
    }
  }
  async function getData() {
    let token = checkLoginToken()
    let body = {
      token: token,
      _id: location.state
    }
    let response: any = await dispatch(getPacksImagesThunk(body))
    if (response.payload.response && response.payload.response.status) {
      if (response.payload.response.status == 401) {
        logoutUser()
      }
      // toast.error(response.payload.response.data.message)
    }
  }
  useEffect(() => {
    getData()

  }, [])
  console.log(imagesOfPacks);
  const removeImageFromPack = async (item: any, pack_id: any) => {
    const token = checkLoginToken()
    try {
      let payload = {
        image_id: item,
        pack_id: pack_id
      }
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/specialpack/removeAssignImage`, payload, { 'headers': { 'Authorization': `${token}` } })
      console.log("response", response);
      getData()
    } catch (error) {
      console.log(error);

    }
  }
  return (
    <div>
      <Sidebar />
      <div className="main_layout">
        <h2>
          Special Pack images
          <h3>{imagesOfPacks.pack_name}</h3>
        </h2>
        {
          imagesOfPacks?.images?.length === 0 ? (
            <h1>Record Not Found</h1>
          ) : (<div className='img_row'>{

            imagesOfPacks?.images?.map((item: any, index: number) => {


              return (

                <div className='img_div1' key={index}  >
                  <div className='div_border'>
                    <button type="button" className="btn-close1" aria-label="Close" onClick={() => removeImageFromPack(item._id, imagesOfPacks._id)}  >
                      <img src={require('../../images/close.png')} alt='' />
                    </button>


                    <div className='img_div_spacing'>
                      {/* <img src={`http://localhost:3015/public/uploads/images/${item.image_low}`} alt="philosophy" /> */}
                      <img src={`${process.env.REACT_APP_BACKEND_BASE_URL}uploads/images/${item.image_hd}`} alt="philosophy" />
                    </div>

                  </div>
                </div>

              )
            }
            )
          }
          </div>)
        }





      </div>

    </div >
  )
}
