import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const api_url = process.env.REACT_APP_BACKEND_API_BASE_URL;


export const getPacksImagesThunk = createAsyncThunk(
    'special_pack/images',
    async (body: any, { rejectWithValue }) => {
        console.log(body);
        
        try {
            const data = await axios.get(`${api_url}/specialpack/images/${body._id}?page=${body.page}&limit=${body.limit}`, { 'headers': { 'Authorization': `${body.token}` } })

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
)

export const getPacksThunk = createAsyncThunk(
    'special_pack/all',
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.get(`${api_url}/specialpack/all?page=${body.page}&limit=${body.limit}`, { 'headers': { 'Authorization': `${body.token}` } })

            return data
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
)

export const addPackThunk = createAsyncThunk(
  "special_pack/add",
  async (body: any, { rejectWithValue }) => {
      try {
          let token = body.token;
          console.log("sdhsdhsds",token);
          
          delete body.token
          const data = await axios.post(`${api_url}/specialpack/add`, body, { 'headers': { 'Authorization': `${token}` } })
          return data
      } catch (error) {
          return rejectWithValue(error)
          // throw error
      }
  }
)

export const deletePackThunk = createAsyncThunk(
    "special_pack/delete",
    async (body: any, { rejectWithValue }) => {
        try {
            let token = body.token;
            console.log("sdhsdhsds",body);
            
            delete body.token
            const data = await axios.delete(`${api_url}/specialpack/delete/${body._id}`, { 'headers': { 'Authorization': `${token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
            // throw error
        }
    }
  )

  export const getPackDetailsThunk = createAsyncThunk(
    'special_pack/details',
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.get(`${api_url}/specialpack/details/${body._id}`, { 'headers': { 'Authorization': `${body.token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

