

export const packsSelector = (state: any) => {
  const {
    totalpacks,
    packs,
    pack,
    page,
    limit,
    loadingStatus,
    getpacksError,
    addpackError,
    deletepackError,
    imagesOfPacks,
    allPack
  } = state.packs
  return {
    totalpacks,
    packs,
    pack,
    page,
    limit,
    loadingStatus,
    getpacksError,
    addpackError,
    deletepackError,
    imagesOfPacks,
    allPack
  }
}