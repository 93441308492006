

export const purchaseSelector = (state: any) => {
  const {
      totalTransactions,
      transactions,
      transaction,
      page,
      limit,
      loadingStatus,
      error,
     
  } = state.transaction
  return {
      totalTransactions,
      transactions,
      transaction,
      page,
      limit,
      loadingStatus,
      error,
      
  }
}