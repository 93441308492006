
import { createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import { toast } from "react-toastify";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Grid, Text,
} from "@mantine/core";
import { loginThunk } from "../../Redux/Thunks/userThunk";
import { setIsLoggedIn, setSingleUser } from "../../Redux/userSlice";
import { Link } from "react-router-dom";
import { useState } from "react";
import LoadingButton from "../../comman/LoadingButton";


export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);


  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be valid")
      .required("Email is required"),
    password: Yup.string().trim()
      .required('Password is required')
      .min(8, 'Password is too short - should be 8 chars minimum.'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (data, { resetForm }) => {
      await handleLogin(data)
      //  resetForm()
    }
  });

  const handleLogin = async (data: any) => {
    try {
      setLoading(true);
      let response: any = await dispatch(loginThunk(data))
      if (response.payload.response && response.payload.response.status) {
        setLoading(false);
        toast.error(response.payload.response.data.message)
      }
      else {
        // console.log(response.payload.data)
        localStorage.setItem("currentToken", response.payload.data.body.token);
        localStorage.setItem("userId", response.payload.data.body.user._id);
        dispatch(setSingleUser(response.payload.data.body.user));
        dispatch(setIsLoggedIn());
        toast.success(response.payload.data.message)
        navigate("/dashboard")
      }
    } catch (err) {
      setLoading(false);


      console.log(err);
    }
  };


  return (
    <div className="add_image-form1 category-frm">


      <form className='add-category' onSubmit={formik.handleSubmit}>
        <div className="h2_div">
          <h2 className="text-center">
            Login
          </h2>
        </div>
        <div className="flex-fg">
          <div className='form-group'>
            <label>Email</label>
            <input
              name="email"
              type="text"
              placeholder="Enter email"
              className={
                'form-control' +
                (formik.errors.email && formik.touched.email
                  ? ' is-invalid'
                  : '')
              }
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <div className="invalid-feedback">
              {formik.errors.email && formik.touched.email
                ? formik.errors.email
                : null}
            </div>
          </div>
          <div className='form-group'>
            <label>Password</label>
            <input
              name="password"
              type="password"
              placeholder="Enter password"
              className={
                'form-control' +
                (formik.errors.password && formik.touched.password
                  ? ' is-invalid'
                  : '')
              }
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            <div className="invalid-feedback">
              {formik.errors.password && formik.touched.password
                ? formik.errors.password
                : null}
            </div>
          </div>
        </div>
        {!isLoading ? (
          <button
            type="submit" className='btn main_btn1 text-white mt-4 sbmt'
          >
            Login
          </button>
        ) : (
          <LoadingButton />
        )}

        {/* <button type="submit" className='btn main_btn1 text-white mt-4 sbmt'>Login</button> */}
        <Link to="/forgot-password" className="fpass">Forget Password?</Link>
      </form>

    </div>

  );
}
