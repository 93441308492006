import Sidebar from "../Sidebar/Sidebar";
import AddEditMain from "./AddEditMain";
 

const AddEdit = () => {
  return (
    <>
      <div className="general-layout">
        <Sidebar />
        <AddEditMain />
      </div>
    </>
  );
};

export default AddEdit;
