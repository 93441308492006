import React, { useEffect, useRef, useState,RefObject } from 'react'
import { ReactComponent as DownArrowSvg } from "../../images/down-arrow-6402.svg"
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from '../../Redux/Selectors/userSelector'
import * as Yup from 'yup';
import MultiSelectDropDown from './MultiSelectDropDown';
import axios from 'axios';
import { useFormik } from 'formik';
import { sendNotificationThunk } from '../../Redux/Thunks/notificationThunk';
import { setIsLoggedIn } from '../../Redux/userSlice';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
const api_url = process.env.REACT_APP_BACKEND_API_BASE_URL;
// type MultiSelectRefType = {
//   resetSelectedValues: () => void;
//   // Add any other properties or methods you need
// };
// type MultiSelectRefType = RefObject<HTMLElement>;
export default function Main() {

  const { users } = useSelector(userSelector)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const multiselectRef=useRef<any>(null);
  const [assignedUser, setAssignedUser] = useState<any[]>([]);
  const [options, setOptions] = useState<any[]>([]);
 
  const [selectedOption, setSelectedOption] = useState('');

 


  function logoutUser() {
    dispatch(setIsLoggedIn());
    localStorage.removeItem("currentToken");
    localStorage.removeItem("userId");
    navigate("/login")
  }

  function checkLoginToken() {
    let token = localStorage.getItem("currentToken")
    if (!token) {
      logoutUser()
    }
    else {
      return token
    }
  }
  let token = checkLoginToken()
 
  const validationSchema = Yup.object().shape({
    notification_title: Yup.string().required("Title is required"),
    notification_description: Yup.string().required("Description is required"),
    user_proile:Yup.string().required("Please select one or more users.")

  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      notification_title: "",
      notification_description: "",
      token: token,


    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      let token = checkLoginToken()
      if (assignedUser.length > 0) {
        let payload = {
          ...values,
          users_id: [...assignedUser],

        }

        let res = await axios.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/notification/sendNotification`, payload, { 'headers': { 'Authorization': `${token}` } })
        if (res.status == 200) {
          toast.success("Notification sent")
        }


      } else {
        let payload = {
          ...values,
          users_id: users.map((item: any) => item.id),

        }
        
        let res = await axios.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/notification/sendNotification`, payload, { 'headers': { 'Authorization': `${token}` } })
        if (res.status == 200) {
          toast.success("Notification sent")
        }
      }
    await  multiselectRef.current.resetSelectedValues()
      setAssignedUser([])
      resetForm()
    }
  })
  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
 

  }



  const getUsersList = () => {

    let tempOptions: any = [];

    users.map((item: any) => {
      tempOptions.push({ name: item.username, id: item._id });
    });


    setOptions([...tempOptions]);

  };
  useEffect(() => {
    getUsersList();
  }, []);
  const getSelectedUser = (userSelected: any) => {
    console.log(userSelected, "userSelected");
    setAssignedUser([...userSelected]);
  };
  return (
    <div className="main_layout">

      <h2>
        Notification
      </h2>
      <div className="add_edit_box">
        <form className='add-category' onSubmit={formik.handleSubmit} >
          <div className="flex-fg">

            <div className='flex_row' >
              <div className='custom_check'>
                <input
                  type='radio'
                  id="Select_All_Users" name="Select_All_Users"
                  value="All Users"
                  checked={selectedOption === 'All Users'}
                  onChange={handleOptionChange}
                />
                <label htmlFor="Select_All_Users">All Users</label>
              </div>

              <div className='custom_check' >
                <input type='radio' id="Select_Users" name="Select_Users"
                  value="Select Users"
                  checked={selectedOption === 'Select Users'}
                  onChange={handleOptionChange}
                />
                <label htmlFor='Select_Users'>Select Users</label>
              </div>
            </div>
            <div className='form-group user_select' style={{ display: selectedOption === "All Users" ? "none" : '' }}>
              <label>Select Users</label>
              <div className="select_icon">
                {/* <DownArrowSvg /> */}
                <MultiSelectDropDown
                  options={options}
                  onSelectUser={getSelectedUser}
                  multiselectRef={multiselectRef}
                />
                <div className="invalid-feedback">
                  {formik.errors.notification_title && formik.touched.notification_title
                    ? formik.errors.notification_title
                    : null}
                </div>
              </div>
            </div>
            <div className='form-group'>
              <label>Notification Title</label>
              <input
                name="notification_title"
                type="text"
                placeholder="Enter Title"
                className="form-control"
                value={formik.values.notification_title}
                onChange={formik.handleChange}



              />
              <div className="invalid-feedback">
                {formik.errors.notification_title && formik.touched.notification_title
                  ? formik.errors.notification_title
                  : null}
              </div>
            </div>
            <div className='form-group'>
              <label>Notification Description</label>
              <textarea
                name="notification_description"
                value={formik.values.notification_description}
                onChange={formik.handleChange}

                placeholder="Enter Description"
                className="form-control"

              />
              <div className="invalid-feedback">
                {formik.errors.notification_description && formik.touched.notification_description
                  ? formik.errors.notification_description
                  : null}
              </div>
            </div>

          </div>

          <button type="submit" className='btn main_btn1 text-white mt-4'>Send Notification</button>
        </form>
      </div>

    </div>
  )
}


