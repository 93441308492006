import { createSlice /* PayloadAction */ } from "@reduxjs/toolkit";

import { addPackThunk, getPacksImagesThunk, getPacksThunk } from "./Thunks/specialPacks";


export interface CategoryState {
    totalpacks: any,
    packs: any,
    pack: any,
    page: any,
    limit: any,
    loadingStatus: any,
    getpacksError: any,
    addpackError: any,
    deletepackError: any,
    imagesOfPacks: any,
    allPack: any

}

const initialState: CategoryState = {
    totalpacks: 1,
    packs: [],
    imagesOfPacks: [],
    pack: {},
    allPack: [],
    page: 1,
    limit: 10,
    loadingStatus: "loading",
    getpacksError: "",
    addpackError: "",
    deletepackError: ""
};


export const packsSlice = createSlice({
    name: "pack",
    initialState,
    reducers: {
        getAll: (state, action) => {
            state.packs = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder

            // Get packs
            .addCase(getPacksThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.getpacksError = "";
                // state.categories = [];
            })
            .addCase(getPacksThunk.fulfilled, (state, action) => {
                console.log("GET fulffilled",   action.payload.data.body.all)
                state.loadingStatus = "";
                state.getpacksError = "";
                state.packs = action.payload.data.body.data;
                state.allPack = action.payload.data.body.all;
                state.totalpacks = action.payload.data.body.count

            })
            .addCase(getPacksThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
                state.getpacksError = action.payload.response.data.message;
            })
            // Get packs images
            .addCase(getPacksImagesThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.getpacksError = "";
                state.imagesOfPacks = [];
            })
            .addCase(getPacksImagesThunk.fulfilled, (state, action) => {
                console.log("GET fulffilled")
                state.loadingStatus = "";
                state.getpacksError = "";
                state.imagesOfPacks = action.payload.data.body.data;
                state.totalpacks = action.payload.data.body.count

            })
            .addCase(getPacksImagesThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
                state.getpacksError = action.payload.response.data.message;
            })

            // Add pack
            .addCase(addPackThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.addpackError = "";
                state.pack = {};
            })
            .addCase(addPackThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.addpackError = "";
                state.pack = {};
            })
            .addCase(addPackThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
                state.addpackError = action.payload.response.data.message;
                state.pack = {};



            })





    }
});

// Action creators are generated for each case reducer function
export const { getAll } =
    packsSlice.actions;

export default packsSlice.reducer;
