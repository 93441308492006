import { createSlice /* PayloadAction */ } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getPurchaseDetailTransactions } from "./Thunks/purchaseDetails";

export interface TransactionState {
   totalTransactions:any
    transactions: any;
    transaction: any;
    page: any;
    limit: any;
    loadingStatus: any;
    error: any;
  
}

const initialState: TransactionState = {
  totalTransactions:1,
    transactions: [],
    transaction: {},
    page: 1,
    limit: 10,
    loadingStatus: "loading",
    error: false,
 
};


export const transactionSlice = createSlice({
    name: "transaction",
    initialState,
    reducers: {
        getAll: (state, action) => {
            state.transactions = action.payload;
        },
        transactionEmpty: (state)=>{
            state.transaction = null
        }
    },
    extraReducers: (builder) => {
        builder

            // Get transactions
            .addCase(getPurchaseDetailTransactions.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.error = false;
            })
            .addCase(getPurchaseDetailTransactions.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.error = false;
                state.transactions = action.payload.data.body.data;
                state.totalTransactions = action.payload.data.body.count
            })
            .addCase(getPurchaseDetailTransactions.rejected, (state, action: any) => {
                state.loadingStatus = "";
                state.error = true;
            })

 





    }
});

// Action creators are generated for each case reducer function
export const { getAll, transactionEmpty } =
transactionSlice.actions;

export default transactionSlice.reducer;
