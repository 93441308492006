import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const api_url = process.env.REACT_APP_BACKEND_API_BASE_URL;

export const getCategoriesThunk = createAsyncThunk(
    'category/all',
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.get(`${api_url}/category/all?page=${body.page}&limit=${body.limit}`, { 'headers': { 'Authorization': `${body.token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const addCategoryThunk = createAsyncThunk(
    "category/create",
    async (body: any, { rejectWithValue }) => {
        try {
            let token = body.token;
            delete body.token
            const data = await axios.post(`${api_url}/category/create`, body, { 'headers': { 'Authorization': `${token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
            // throw error
        }
    }
)

export const deleteCategoryThunk = createAsyncThunk(
    "category/delete",
    async (body: any, { rejectWithValue }) => {
        try {
            const data = await axios.delete(`${api_url}/category/delete/${body._id}`, { 'headers': { 'Authorization': `${body.token}` } })
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)


