import { createSlice /* PayloadAction */ } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getSubCategoriesThunk, getAllActiveCategoriesThunk, addSubCategoryThunk, deleteSubCategoryThunk } from "./Thunks/subcategoryThunk";
import { addPublishersThunk, getPublishersThunk } from "./Thunks/publisherThunk";

export interface PublisherState {
    publishers: any;
    publisher: Object;
    totalPublishers: any;
   
    page: any;
    limit: any;
    loadingStatus: any;
    getPublishersError: any;
    getAllActivePublishersError: any;
    addPublishersError: any;
    deletePublishersError: any
}

const initialState: PublisherState = {
  publishers: [],
    publisher: {},
    totalPublishers: 1,
   
    page: 1,
    limit: 10,
    loadingStatus: "loading",
    getPublishersError: "any",
    getAllActivePublishersError: "",
    addPublishersError: "",
    deletePublishersError: ""

    
};


export const purchaseSlice = createSlice({
    name: "publisher",
    initialState,
    reducers: {
        getAll: (state, action) => {
            state.publishers = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder

            // Get Subcategories
            .addCase(getPublishersThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.getPublishersError = "";
                // state.subcategories = [];
            })
            .addCase(getPublishersThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.getPublishersError = "";
                state.publishers = action.payload.data.body.data;
                state.totalPublishers = action.payload.data.body.count
            })
            .addCase(getPublishersThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
                state.getPublishersError = action.payload.response.data.message;
            })

            // Add SubCategory
            .addCase(addPublishersThunk.pending, (state, action: any) => {
                state.loadingStatus = "loading";
                state.addPublishersError = "";
                state.publisher = {};
            })
            .addCase(addPublishersThunk.fulfilled, (state, action) => {
                state.loadingStatus = "";
                state.addPublishersError = ""
                state.publisher = {};
            })
            .addCase(addPublishersThunk.rejected, (state, action: any) => {
                state.loadingStatus = "";
                state.addPublishersError = action.payload.response.data.message;
                state.publisher = {};

                // toast.error(action.payload.response.data.message)
            })

            // Get All Active Categories
            // .addCase(getAllActivePublishersThunk.pending, (state, action: any) => {
            //     state.loadingStatus = "loading";
            //     state.getAllActivePublishersError = "";
            // })
            // .addCase(getAllActivePublishersThunk.fulfilled, (state, action) => {
            //     state.loadingStatus = "";
            //     state.getAllActivePublishersError = "";
                
            // })
            // .addCase(getAllActivePublishersThunk.rejected, (state, action: any) => {
            //     state.loadingStatus = "";
            //     state.getAllActivePublishersError = action.payload.response.data.message;
            // })




            // Delete SubCategory
            // .addCase(deletePublisher.pending, (state, action: any) => {
            //     state.loadingStatus = "loading";
            //     state.deletePublishersError = "";
            // })
            // .addCase(deleteSubCategoryThunk.fulfilled, (state, action) => {
            //     state.loadingStatus = "";
            //     state.deletePublishersError = "";
            // })
            // .addCase(deleteSubCategoryThunk.rejected, (state, action: any) => {
            //     state.loadingStatus = "";
            //     state.deletePublishersError = action.payload.response.data.message;
            // })

    }
});

// Action creators are generated for each case reducer function
export const { getAll } =
    purchaseSlice.actions;

export default purchaseSlice.reducer;
