

export const subcategorySelector = (state: any) => {
    const {
        totalSubcategories,
        subcategories,
        subcategory,
        activeCategories,
        page,
        limit,
        loadingStatus,
        getSubCategoriesError,
        getAllActiveCategoriesError,
        addSubCategoryError,
        deleteSubCategoryError
    } = state.subcategory
    return {
        totalSubcategories,
        subcategories,
        subcategory,
        activeCategories,
        page,
        limit,
        loadingStatus,
        getSubCategoriesError,
        getAllActiveCategoriesError,
        addSubCategoryError,
        deleteSubCategoryError
    }
}