import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MantineProvider } from "@mantine/core";

import "./App.css";
import Home from "./components/Home/Home";
import Signup from "./components/Signup/Signup";
import Login from "./components/Login/Login";
import ProtectedRoute from "./components/ProtectedRoutes/ProtectedRoute";
import Settings from "./components/Setting/Settings";
import Category from "./components/Categories/Category";
import Subcategory from "./components/Subcategories/Subcategory";
import Users from "./components/Users/Users";
import Images from "./components/Images/Image";
import AddEditImage from "./components/Images/AddEdit";
import AddEditCategory from './components/Categories/AddEdit'
import AddEditPack from './components/Packs/AddEdit'

import AddEditSubCategory from './components/Subcategories/AddEdit'
import Dashboard from "./components/Dashboard/Dashboard";
import Notifications from "./components/Notifications/Notification";
import Packs from "./components/Packs/Packs";
import PacksImages from "./components/Packs/PacksImages";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import Information from "./components/ForgotPassword/Information";
import PageNotFound from "./components/ForgotPassword/PageNotFound";
import EditUser from "./components/Users/AddEditUser";
import Publisher from "./components/Publisher/Publisher";
import AddEditPublisher from "./components/Publisher/AddEdit";


function App() {
  return (
    <MantineProvider theme={{}}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/reset-password" element={<ResetPassword />}></Route>
          <Route path="*" element={<PageNotFound />}></Route>

          <Route path="/info" element={<Information />}></Route>




          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/addedituser"
            element={
              <ProtectedRoute>
                <EditUser />
              </ProtectedRoute>
            }
          />

          <Route
            path="/packs"
            element={
              <ProtectedRoute>
                <Packs />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pack/images"
            element={
              <ProtectedRoute>
                <PacksImages />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pack/addedit"
            element={
              <ProtectedRoute>
                <AddEditPack />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedRoute>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path="/notification"
            element={
              <ProtectedRoute>
                <Notifications />
              </ProtectedRoute>
            }
          />
          <Route
            path="/categories"
            element={
              <ProtectedRoute>
                <Category />
              </ProtectedRoute>
            }
          />


          <Route
            path="/category/addedit"
            element={
              <ProtectedRoute>
                <AddEditCategory />
              </ProtectedRoute>
            }
          />

          <Route
            path="/subcategories"
            element={
              <ProtectedRoute>
                <Subcategory />
              </ProtectedRoute>
            }
          />

          <Route
            path="/subcategory/addedit"
            element={
              <ProtectedRoute>
                <AddEditSubCategory />
              </ProtectedRoute>
            }
          />

          <Route
            path="/images"
            element={
              <ProtectedRoute>
                <Images />
              </ProtectedRoute>
            }
          />

          <Route
            path="/image/addedit"
            element={
              <ProtectedRoute>
                <AddEditImage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/settings/:username"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/publisher"
            element={<ProtectedRoute>
              <Publisher />
            </ProtectedRoute>}
          />
          <Route
            path="/publisher/addedit"
            element={<ProtectedRoute>
              <AddEditPublisher />
            </ProtectedRoute>}
          />
       
        </Routes>

      </BrowserRouter>
    </MantineProvider>
  );
}

export default App;
