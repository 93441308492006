import {
  Container, Grid, Text,
  Table,
} from "@mantine/core";

import { useEffect, useState } from "react";
import {

} from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "@mui/material";
import { ReactComponent as DeleteSvg } from '../../images/delete-svgrepo-com.svg'
import { ReactComponent as EditSvg } from '../../images/edit-button-svgrepo-com.svg'
import { getSubCategoriesThunk, deleteSubCategoryThunk } from "../../Redux/Thunks/subcategoryThunk";
import { subcategorySelector } from "../../Redux/Selectors/subcategorySelector";
import AlertDialog from "../Dialog/Alert";

import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router";
import { setIsLoggedIn } from "../../Redux/userSlice";
import { HashLoader } from "react-spinners";
import { publisherSelector } from "../../Redux/Selectors/publisherSelector";
import { getPublishersThunk } from "../../Redux/Thunks/publisherThunk";
import { deletePublishersThunk } from "../../Redux/Thunks/publisherThunk";



 

const Main = () => {

  const page: any = process.env.REACT_APP_PAGE
  const limit: any = process.env.REACT_APP_LIMIT

  const [selectedPage, setSelectedPage] = useState<any>(parseInt(page))
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const { publishers, totalPublishers }: any = useSelector(publisherSelector)
console.log("publisher",publishers);

  function logoutUser() {
    dispatch(setIsLoggedIn());
    localStorage.removeItem("currentToken");
    localStorage.removeItem("userId");
    navigate("/login")
  }

  function checkLoginToken() {
    let token = localStorage.getItem("currentToken")
    if (!token) {
      logoutUser()
    }
    else {
      return token
    }
  }


  async function getData(data: any) {

    let token = checkLoginToken()
    setLoading(true)
    let response: any = await dispatch(getPublishersThunk(
      {
        page: data.page,
        limit: data.limit,
        token: token
      }
    ))
    if (response.payload.response && response.payload.response.status) {
      setLoading(false)
      if (response.payload.response.status == 401) {
        logoutUser()
      }
      toast.error(response.payload.response.data.message)
    }
    else {
      setLoading(false)
      pageCounterr()
    }
  }

  useEffect(() => {
    pageCounterr()
  }, [totalPublishers])


  useEffect(() => {
    if ((!publishers || publishers?.length == 0) && selectedPage > 1) {
      setSelectedPage(selectedPage - 1)

      getData({
        page: selectedPage - 1,
        limit: limit
      })

    }
  }, [publishers])

  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    getData({
      page: selectedPage,
      limit: limit
    })
  }, [])


  const handleEdit = (e: any, data: any) => {
    e.preventDefault()
    navigate("/publisher/addedit", { state: data })
  }


  const [deleteDialog, setDeleteDialog] = useState(false)
  const [elementId, setElementId] = useState("")

  const handleDeleteModal = async (e: any, _id: any) => {
    setDeleteDialog(true)
    setElementId(_id)
  }

  const handleDeleteCategory = async (id: any) => {
    try {

      let token = checkLoginToken()

      let payload = {
        _id: id,
        token: token
      }
      let response: any = await dispatch(deletePublishersThunk(payload))





      if (response.payload.response && response.payload.response.status) {
        if (response.payload.response.status == 401) {
          logoutUser()
        }
        toast.error(response.payload.response.data.message)
      }

      if (response.payload.data && response.payload.data.success) {
        toast.success(response.payload.data.message)
        await getData({
          page: selectedPage,
          limit: limit
        })

        setDeleteDialog(false)
        setElementId("")
      }

    } catch (err) {
      console.log(err);
    }
  };


  const rows = publishers && publishers?.map((row: any) => (
    <tr key={row._id}>
     
      <td>{row.name}</td>
      

      <td>
        <Button className="a-dactive" variant="outlined" color={row.status ? "success" : "error"}>
          {row.status ? "Active" : "Inactive"}
        </Button>
      </td>
      <td>{row.createdAt.split("T")[0]}</td>
      <td>{row.updatedAt.split("T")[0]}</td>
      <td>
        <EditSvg height={"15px"} style={{ cursor: "pointer" }} onClick={(e) => handleEdit(e, row)} />
        <DeleteSvg height={"15px"} style={{ cursor: "pointer" }} onClick={(e) =>
          handleDeleteModal(e, row._id)
        } />
      </td>
    </tr>
  ));


  const pageCounterr = () => {
    const pageCounter: any = Math.ceil(parseInt(totalPublishers) / parseInt(limit))
    setPageCount(pageCounter)
  }

  const handlePageClick = async (data: any) => {
    setSelectedPage(+data.selected + 1)
    await getData({ page: +data.selected + 1, limit: 10 })
  }

  return (
    <>
     {
       ! isLoading ? (
      <div className="main_layout">
      <h2 className="subbtn">Publisher
      <button className="btn add_btn" color="warning" onClick={() => {
              navigate("/publisher/addedit")
            }}>Add Publisher</button>

      </h2>
           
      <div  className="table-responsive">
              <table>
                <thead>
                  <tr>
                    
                    <th>Name</th>
                   
                    <th>Status</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {(rows && rows.length >= 0) && (
                    rows
                  )
                  }
                </tbody>
              </table>
            </div>


        <AlertDialog open={deleteDialog} setOpen={setDeleteDialog} handleAgree={() => {
          handleDeleteCategory(elementId)
          
        }} />

        {/* {
          (subcategories && subcategories.length > 0) && (
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              //    breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"pagination_prev"}
              previousLinkClassName={"page-link"}
              nextClassName={"pagination_next"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              forcePage={selectedPage - 1}

            />
          )
        } */}


      </div>
         ) : (
          <div className="spinner" >
           
            <HashLoader color="#1864ab" size={75} loading={true} />
          </div>
        )
      }
    </>
  );
};

export default Main;
