

export const publisherSelector = (state: any) => {
  const {
    publishers,
    publisher,
    totalPublishers,

    page,
    limit,
    loadingStatus,
    getPublishersError,
    getAllActivePublishersError,
    addPublishersError,
    deletePublishersError
  } = state.publisher
  return {
    publishers,
    publisher,
    totalPublishers,

    page,
    limit,
    loadingStatus,
    getPublishersError,
    getAllActivePublishersError,
    addPublishersError,
    deletePublishersError
  }
}