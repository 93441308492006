import Sidebar from '../../components/Sidebar/Sidebar'
import React from 'react'
import Main from './Main'

export default function Packs() {
  return (
    <div>
      <Sidebar />
      <Main />
    </div>
  )
}
