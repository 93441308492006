import {
  Container, Grid, Text,
  Table,
} from "@mantine/core";

import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { deleteCategoryThunk, getCategoriesThunk } from "../../Redux/Thunks/categoryThunks";
import { categorySelector } from "../../Redux/Selectors/categorySelector";
import { Button } from "@mui/material";
import { ReactComponent as DeleteSvg } from '../../images/delete-svgrepo-com.svg'
import { ReactComponent as EditSvg } from '../../images/edit-button-svgrepo-com.svg'
import AlertDialog from "../Dialog/Alert";
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router";
import { setIsLoggedIn } from "../../Redux/userSlice";
import { HashLoader } from "react-spinners";
const imagefolder = "categories"

const Main = () => {

  const page: any = process.env.REACT_APP_PAGE
  const limit: any = process.env.REACT_APP_LIMIT

  const [selectedPage, setSelectedPage] = useState<any>(parseInt(page))
  const [pageCount, setPageCount] = useState(1);
  const dispatch = useDispatch()

  const { categories, totalCategories }: any = useSelector(categorySelector)
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate()

  function logoutUser() {
    dispatch(setIsLoggedIn());
    localStorage.removeItem("currentToken");
    localStorage.removeItem("userId");
    navigate("/login")
  }

  function checkLoginToken() {
    let token = localStorage.getItem("currentToken")
    if (!token) {
      logoutUser()
    }
    else {
      return token
    }
  }

  async function getData(data: any) {
    let token = checkLoginToken()
    setLoading(true)

    let response: any = await dispatch(getCategoriesThunk(
      {
        page: data.page,
        limit: data.limit,
        token: token
      }
    ))

    if (response.payload.response && response.payload.response.status) {
    setLoading(false)

      if (response.payload.response.status == 401) {
    setLoading(false)

        logoutUser()
      }
      toast.error(response.payload.response.data.message)
    }
    else {
    setLoading(false)

      pageCounterr()
    }
  }

  useEffect(() => {
    pageCounterr()
  }, [totalCategories])

  console.log('pageCount',pageCount);
  useEffect(() => {
    if ((!categories || categories.length == 0) && selectedPage > 1) {
      setSelectedPage(selectedPage - 1)

      getData({
        page: selectedPage - 1,
        limit: limit
      })

    }
  }, [categories])

 

  useEffect(() => {
    getData({
      page: selectedPage,
      limit: limit
    })
  }, [])

  const handleEdit = (e: any, data: any) => {
    e.preventDefault()
    navigate("/category/addedit", { state: data })
  }

  const [deleteDialog, setDeleteDialog] = useState(false)
  const [elementId, setElementId] = useState("")

  const handleDeleteModal = async (e: any, _id: any) => {
    setDeleteDialog(true)
    setElementId(_id)
  }

  const handleDeleteCategory = async (id: any) => {
    try {
      let token = checkLoginToken()
      let payload = {
        _id: id,
        token: token
      }

      let response: any = await dispatch(deleteCategoryThunk(payload))

      if (response.payload.response && response.payload.response.status) {
        if (response.payload.response.status == 401) {
          logoutUser()
        }
        toast.error(response.payload.response.data.message)
      }


      if (response.payload.data && response.payload.data.success) {
        toast.success(response.payload.data.message)
        await getData({
          page: selectedPage,
          limit: limit
        })
        setDeleteDialog(false)
        setElementId("")
      }

    } catch (err) {
      console.log(err);
    }
  };

  const rows = categories && categories.map((row: any) => (
    <tr key={row._id}>
      <td>{row.image ?
        <img src={(process.env.REACT_APP_BACKEND_BASE_URL + `uploads/${imagefolder}/` + row.image)} alt={row.image} height="80px" />
        : "Not found"}
      </td>
      <td>{row.name}</td>
      <td>
        <Button className="a-dactive" variant="outlined" color={row.status ? "success" : "error"}>
          {row.status ? "Active" : "Inactive"}
        </Button>
      </td>
      <td>{row.createdAt.split("T")[0]}</td>
      <td>{row.updatedAt.split("T")[0]}</td>
      <td>
        <EditSvg height={"15px"} style={{ cursor: "pointer" }} onClick={(e) => handleEdit(e, row)} />
        <DeleteSvg height={"15px"} style={{ cursor: "pointer" }} onClick={(e) =>
          handleDeleteModal(e, row._id)
        } />
      </td>
    </tr>
  ));


  const pageCounterr = () => {
    const pageCounter: any = Math.ceil(parseInt(totalCategories) / parseInt(limit))
    setPageCount(pageCounter)
  }

  const handlePageClick = async (data: any) => {
    setSelectedPage(+data.selected + 1)
    await getData({ page: +data.selected + 1, limit: 10 })
  }

  return (
    <>
      {
       ! isLoading ? (
     <div className="main_layout">
     <h2>Categories
     <button color="warning" className="btn add_btn" onClick={() => {
              navigate("/category/addedit")
            }}>Add Category</button>

     </h2>
           
           <div  className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {(rows && rows.length >= 0) && (
                    rows
                  )
                  }
                </tbody>
              </table>
            </div>

        <AlertDialog open={deleteDialog} setOpen={setDeleteDialog} handleAgree={() => {
          handleDeleteCategory(elementId)
        }} />

        {
          (categories && categories.length > 0) && (
            <ReactPaginate

              previousLabel={"<"}
              nextLabel={">"}
              //    breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"pagination_prev"}
              previousLinkClassName={"page-link"}
              nextClassName={"pagination_next"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              forcePage={selectedPage - 1}
            />
          )
        }

      </div>
      ) : (
          <div className="spinner" >
           
            <HashLoader color="#1864ab" size={75} loading={true} />
          </div>
        )
      }
    </>
  );
};

export default Main;
